import { render, staticRenderFns } from "./OrganismImageSlider.vue?vue&type=template&id=04d2a8e4&"
import script from "./OrganismImageSlider.vue?vue&type=script&lang=js&"
export * from "./OrganismImageSlider.vue?vue&type=script&lang=js&"
import style0 from "./OrganismImageSlider.vue?vue&type=style&index=0&id=04d2a8e4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismImageSliderV2.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fcommon%2FOrganismImageSlider.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeImageSlider: require('/app/components/molecules/common/MoleculeImageSlider.vue').default,MoleculesCommonMoleculeImageThumbSlider: require('/app/components/molecules/common/MoleculeImageThumbSlider.vue').default})

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sectionToObserve",staticClass:"organism-parking-access-v2"},[_c('div',{staticClass:"parking-access"},_vm._l((_vm.parkingItems),function(parking,index1){return _c('span',{key:index1,staticClass:"button-tab-parking-access",class:{ active: _vm.activeTab === parking.slug },on:{"click":function($event){return _vm.playAnimation(`${parking.slug}`)}}},[_vm._v("\n      "+_vm._s(parking.name)+"\n    ")])}),0),_vm._v(" "),_vm._l((_vm.parkingItems),function(parking,index2){return _c('div',{key:index2,staticClass:"animation-tab-parking-access"},[(_vm.activeTab === parking.slug)?_c('div',[_c('picture',[_c('source',{attrs:{"srcset":_vm.assetUrl(parking.image)}}),_vm._v(" "),(parking.image)?_c('img',{attrs:{"src":_vm.assetUrl(parking.image),"alt":`Parking-${parking.name}`}}):_vm._e()]),_vm._v(" "),(_vm.animationsLoaded)?_c('div',{staticClass:"animation",attrs:{"id":`${parking.slug}`}},[_c('AtomsCommonAtomLottieAnimation',{attrs:{"options":{
            loop: false,
            autoplay: _vm.skipAnimation === false && _vm.activeTab === parking.slug,
            animationData: parking.animation,
            rendererSettings: {
              progressiveLoad: false
            }
          }},on:{"animCreated":($event) => _vm.handleAnimation($event, `${parking.slug}`)}})],1):_vm._e()]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"organism-parking-access"},[_c('picture',[_c('source',{attrs:{"srcset":_vm.assetUrl}}),_vm._v(" "),(_vm.assetUrl)?_c('img',{attrs:{"src":_vm.assetUrl,"alt":"Image Parking"}}):_vm._e()]),_vm._v(" "),_vm._l((_vm.parkingAccess),function(building,index){return _c('div',{key:index,staticClass:"wrapper-parking-access"},[_c('div',{staticClass:"parking-access",class:{ 'more-parking': building.marginLeft },style:({ 'margin-left': building.marginLeft })},[_c('p',{domProps:{"innerHTML":_vm._s(building.name)}}),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('span',{on:{"click":function($event){return _vm.playEntries(`${building.slug}`)}}},[_vm._v("Entries")]),_vm._v(" "),_c('span',{on:{"click":function($event){return _vm.playExits(`${building.slug}`)}}},[_vm._v("Exits")])])]),_vm._v(" "),(_vm.showEntries[building.slug])?_c('div',{staticClass:"animation",attrs:{"id":"animation-entries"}},[_c('AtomsCommonAtomLottieAnimation',{attrs:{"options":{
          loop: false,
          autoplay: false,
          animationData: building.animations.entries,
          rendererSettings: {
            progressiveLoad: false
          }
        }},on:{"animCreated":($event) => _vm.handleAnimation($event, `${building.slug}-entries`)}})],1):_vm._e(),_vm._v(" "),(_vm.showExits[building.slug])?_c('div',{staticClass:"animation",attrs:{"id":"animation-exits"}},[_c('AtomsCommonAtomLottieAnimation',{attrs:{"options":{
          loop: false,
          autoplay: false,
          animationData: building.animations.exits,
          rendererSettings: {
            progressiveLoad: false
          }
        }},on:{"animCreated":($event) => _vm.handleAnimation($event, `${building.slug}-exits`)}})],1):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }